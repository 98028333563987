import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {Button, Checkbox, FormControlLabel, FormGroup, Grid, TextField} from '@mui/material';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import {
  AccountRes, CircuitVectorPostReq, ConfirmationRes, GenerationTypeEnum, GenerationTypeVectorPostReq, PolygonPostReq,
} from '../shared/types';
import {buttonStyle, modalStyle, textFieldStyle} from '../style';
import {assertNever, getSetter, queryApi} from '../utils/misc';
import {useParams} from 'react-router-dom';
import {formatCircuitVector, formatGenerationTypeVector} from '../utils/textFormatting';
import {isPolygon, parsePolygonStr} from '../utils/stringValidators';
import {useCircuits, usePlant} from '../data/queries';
import {useRecoilValue} from 'recoil';
import {accountAtom} from '../data/atoms';
import {SET_CIRCUIT_VECTORS, SET_GENERATION_TYPE_VECTORS, SET_POLYGONS} from '../data/endpoints';
import {formatAndLinkTxHash} from '../utils/linkFormatting';
import {getPolygonAsMap} from '../utils/maps';

type MODAL_TYPE = 'circuitVector' | 'generationTypeVector' | 'polygon';

/* eslint-disable-next-line max-lines-per-function */
export default function PlantSettings() {
  const {plantId} = useParams();

  const [modalType, setModalType] = useState<MODAL_TYPE | null>(null);
  const [selectedCircuitNames, setSelectedCircuitNames] = useState<string[]>([]);
  const [selectedGenerationTypes, setSelectedGenerationTypes] = useState<GenerationTypeEnum[]>([]);
  const [polygonStr, setPolygonStr] = useState('');

  const account = useRecoilValue<AccountRes | null>(accountAtom);

  const {data: plant, refetch: refetchPlant} = usePlant(account?.id ?? null, plantId ?? '');
  const {data: circuits} = useCircuits(account?.id ?? null);

  const isPolygonStrValid = isPolygon(polygonStr);

  useEffect(() => {
    if(plant === undefined)
      return;

    setSelectedCircuitNames([plant.circuit_name]);

    if(plant.generation_type === null)
      return;

    setSelectedGenerationTypes([plant.generation_type]);
  }, [plant]);

  function setCircuitNameSelection(isChecked: boolean, circuitName: string) {
    if(isChecked) {
      setSelectedCircuitNames([...selectedCircuitNames, circuitName]);
    } else {
      setSelectedCircuitNames(selectedCircuitNames.filter(cn => cn !== circuitName));
    }
  }

  function setGenerationTypeSelection(isChecked: boolean, generationType: GenerationTypeEnum) {
    if(isChecked) {
      setSelectedGenerationTypes([...selectedGenerationTypes, generationType]);
    } else {
      setSelectedGenerationTypes(selectedGenerationTypes.filter(gt => gt !== generationType));
    }
  }

  async function submitCircuitVector() {
    if(plantId === undefined)
      throw new Error('plant ID or plant is undefined');

    if(plant === undefined)
      throw new Error('plant is null exexpectedly');

    const url = SET_CIRCUIT_VECTORS.replace('{plantId}', plantId);
    const body: CircuitVectorPostReq = {
      circuit_vector: selectedCircuitNames,
    };
    const result = await queryApi<ConfirmationRes>(url, account?.id, 'post', body);

    if(result?.status !== 'ok') {
      console.error('did not get status ok');
      return;
    }

    setSelectedCircuitNames([plant.circuit_name]);

    await refetchPlant();
  }

  async function submitGenerationTypeVector() {
    if(plantId === undefined)
      throw new Error('plant ID or plant is undefined');

    if(plant === undefined || plant.generation_type === null)
      throw new Error('plant or generation type is null exexpectedly');

    const url = SET_GENERATION_TYPE_VECTORS.replace('{plantId}', plantId);
    const body: GenerationTypeVectorPostReq = {
      generation_type_vector: selectedGenerationTypes,
    };
    const result = await queryApi<ConfirmationRes>(url, account?.id, 'post', body);

    if(result?.status !== 'ok') {
      console.error('did not get status ok');
      return;
    }

    setSelectedGenerationTypes([plant.generation_type]);

    await refetchPlant();
  }

  async function submitPolygon() {
    if(plantId === undefined)
      throw new Error('plant ID is undefined');

    const url = SET_POLYGONS.replace('{plantId}', plantId);
    const body: PolygonPostReq = {
      polygon: parsePolygonStr(polygonStr),
    };
    const result = await queryApi<ConfirmationRes>(url, account?.id, 'post', body);

    if(result?.status !== 'ok') {
      console.error('did not get status ok');
      return;
    }

    setPolygonStr('');

    await refetchPlant();
  }

  return plant === undefined ? 'loading...' :
    <>
      <Box sx={{m: 5}}>
        <Stack direction="row" sx={{width: '100%'}} spacing={2} justifyContent="space-between" alignItems="center">
          <Typography
            sx={{my: 5}}
            variant="h5"
            id="tableTitle"
            component="div"
          >
            Anlageneigenschaften
          </Typography>
          <Stack direction="row" sx={{width: '100%'}} spacing={2} justifyContent="flex-end" alignItems="center">
            <Button
              variant="outlined"
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                event.stopPropagation();
                event.preventDefault();
                setModalType('circuitVector');
              }}
              sx={{mt: 1, mr: 1, width: '200px'}}
            >
              Netzstrangvektor hinzufügen
            </Button>

            { // Only show if the selected plant is a generation plant
              plant !== undefined && plant.generation_type !== null &&
              <Button
                variant="outlined"
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  event.stopPropagation();
                  event.preventDefault();
                  setModalType('generationTypeVector');
                }}
                sx={{mt: 1, mr: 1, width: '200px'}}
              >
                Energieartvektor hinzufügen
              </Button>
            }

            <Button
              variant="outlined"
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                event.stopPropagation();
                event.preventDefault();
                setModalType('polygon');
              }}
              sx={{mt: 1, mr: 1, width: '200px'}}
            >
              Polygon hinzufügen
            </Button>
          </Stack>
        </Stack>

        <TableContainer component={Paper} sx={{mt: 5}}>
          <Table sx={{minWidth: 650}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Index</TableCell>
                <TableCell align="center" width="70%">Netzstrangvektor</TableCell>
                <TableCell align="center" width="15%">Transaktions-Hash</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(plant.circuit_vectors ?? []).map((circuitVector, i) => (
                <TableRow
                  key={i}
                  sx={{'&:last-child td, &:last-child th': {border: 0}}}
                >
                  <TableCell component="th" scope="row">
                    {i}
                  </TableCell>
                  <TableCell align="center">
                    {formatCircuitVector(circuitVector)}
                  </TableCell>
                  <TableCell align="center">
                    {formatAndLinkTxHash(circuitVector.tx_hash)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        { // Only show if the selected plant is a generation plant
          plant !== undefined && plant.generation_type !== null &&
          <TableContainer component={Paper} sx={{mt: 5}}>
            <Table sx={{minWidth: 650}} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Index</TableCell>
                  <TableCell align="center" width="70%">Energieartenvektor</TableCell>
                  <TableCell align="center" width="15%">Transaktions-Hash</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(plant.generation_type_vectors ?? []).map((generationTypeVector, i) => (
                  <TableRow
                    key={i}
                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                  >
                    <TableCell component="th" scope="row">
                      {i}
                    </TableCell>
                    <TableCell align="center">
                      {formatGenerationTypeVector(generationTypeVector)}
                    </TableCell>
                    <TableCell align="center">
                      {formatAndLinkTxHash(generationTypeVector.tx_hash)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }

        <TableContainer component={Paper} sx={{mt: 5}}>
          <Table sx={{minWidth: 650}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Index</TableCell>
                <TableCell align="center" width="70%">Polygon</TableCell>
                <TableCell align="center" width="15%">Transaktions-Hash</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(plant.polygons ?? []).map((polygon, i) => (
                <TableRow
                  key={i}
                  sx={{'&:last-child td, &:last-child th': {border: 0}}}
                >
                  <TableCell component="th" scope="row">
                    {i}
                  </TableCell>
                  <TableCell align="center">
                    {getPolygonAsMap(polygon.geo_points)}
                  </TableCell>
                  <TableCell align="center">
                    {formatAndLinkTxHash(polygon.tx_hash)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

      </Box>

      {modalType === 'circuitVector' &&
        <Modal
          hideBackdrop
          open={true}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          disableScrollLock={true}
        >
          <Box sx={{...modalStyle, width: 500, maxWidth: 'calc(100% - 32px - 32px)', overflow: 'scroll'}}>
            <h2 id="child-modal-title">{`${modalTypeToString(modalType)} hinzufügen`}</h2>
            <form onSubmit={e => {
              e.preventDefault();
              submitCircuitVector().catch(console.error);
            }}>
              <Grid container>
                <Grid item xs={12}>
                  <div style={{maxHeight: 300, overflow: 'scroll'}}>
                    <FormGroup>
                      {(circuits ?? []).map((circuit, i) =>
                        <FormControlLabel
                          key={i}
                          disabled={circuit.name === plant.circuit_name}
                          control={
                            <Checkbox
                              checked={selectedCircuitNames.includes(circuit.name)}
                              onChange={(_e, isChecked) => setCircuitNameSelection(isChecked, circuit.name)}
                            />
                          }
                          label={circuit.name}
                        />
                      )}
                    </FormGroup>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    style={buttonStyle}
                    variant="contained"
                    type="submit"
                  >
                    abschicken
                  </Button>
                </Grid>
              </Grid>
            </form>
            <div style={{display: 'flex'}}>
              <Button onClick={() => setModalType(null)} style={{marginLeft: 'auto'}}>close</Button>
            </div>
          </Box>
        </Modal>
      }

      {modalType === 'generationTypeVector' &&
        <Modal
          hideBackdrop
          open={true}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          disableScrollLock={true}
        >
          <Box sx={{...modalStyle, width: 500, maxWidth: 'calc(100% - 32px - 32px)', overflow: 'scroll'}}>
            <h2 id="child-modal-title">{`${modalTypeToString(modalType)} hinzufügen`}</h2>
            <form onSubmit={e => {
              e.preventDefault();
              submitGenerationTypeVector().catch(console.error);
            }}>
              <Grid container>
                <Grid item xs={12}>
                  <div style={{maxHeight: 300, overflow: 'scroll'}}>
                    <FormGroup>
                      {Object.values(GenerationTypeEnum).map((generationType, i) =>
                        <FormControlLabel
                          key={i}
                          disabled={generationType === plant.generation_type}
                          control={
                            <Checkbox
                              checked={selectedGenerationTypes.includes(generationType)}
                              onChange={(_e, isChecked) => setGenerationTypeSelection(isChecked, generationType)}
                            />
                          }
                          label={generationType}
                        />
                      )}
                    </FormGroup>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    style={buttonStyle}
                    variant="contained"
                    type="submit"
                  >
                    abschicken
                  </Button>
                </Grid>
              </Grid>
            </form>
            <div style={{display: 'flex'}}>
              <Button onClick={() => setModalType(null)} style={{marginLeft: 'auto'}}>close</Button>
            </div>
          </Box>
        </Modal>
      }

      {modalType === 'polygon' &&
        <Modal
          hideBackdrop
          open={true}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          disableScrollLock={true}
        >
          <Box sx={{...modalStyle, width: 500, maxWidth: 'calc(100% - 32px - 32px)', overflow: 'scroll'}}>
            <h2 id="child-modal-title">{`${modalTypeToString(modalType)} hinzufügen`}</h2>
            <form onSubmit={e => {
              e.preventDefault();
              submitPolygon().catch(console.error);
            }}>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    style={{...textFieldStyle, width: 300 + 2 * textFieldStyle.margin}}
                    label="Polygon"
                    value={polygonStr}
                    onChange={getSetter(setPolygonStr)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    style={buttonStyle}
                    variant="contained"
                    type="submit"
                    disabled={!isPolygonStrValid}
                  >
                    abschicken
                  </Button>
                </Grid>
              </Grid>
            </form>
            <div style={{display: 'flex'}}>
              <Button onClick={() => setModalType(null)} style={{marginLeft: 'auto'}}>close</Button>
            </div>
          </Box>
        </Modal>
      }
    </>;
}

function modalTypeToString(modalType: MODAL_TYPE) {
  switch(modalType) {
    case 'circuitVector':
      return 'Netzstrangvektor';
    case 'generationTypeVector':
      return 'Energieartvektor';
    case 'polygon':
      return 'Polygon';
    default:
      assertNever(modalType);
  }
}

import {useState} from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {Button, Grid, TextField} from '@mui/material';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import {LocalizationProvider} from '@mui/x-date-pickers';
import 'dayjs/locale/de';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {useNavigate, useParams} from 'react-router-dom';

import {useMeterValues} from '../data/queries';

import {useRecoilValue} from 'recoil';
import {accountAtom} from '../data/atoms';

import {
  AccountRes, ConfirmationRes, MeterValuePostReq,
} from '../shared/types';
import {buttonStyle, modalStyle, textFieldStyle} from '../style';
import {getSetter, queryApi} from '../utils/misc';
import {getBalancePeriod, getCurrentBalancePeriod, getDateFromBalancePeriod, isValidBalancePeriod} from '../utils/dateAndTime';
import {SET_METER_VALUES} from '../data/endpoints';
import {isNonNegativeInt} from '../utils/stringValidators';
import {formatDateAndTime, formatGenerationTypes, formatPercentage, functionCallOrDefault} from '../utils/textFormatting';
import {formatAndLinkTxHash} from '../utils/linkFormatting';

/* eslint-disable-next-line max-lines-per-function */
export default function PlantMeterValues() {
  const navigate = useNavigate();

  const [isDocumentationModalOpen, setIsDocumentationModalOpen] = useState(false);
  const [dateAndTime, setDateAndTime] = useState(getCurrentBalancePeriod());
  const [meterValue, setMeterValue] = useState('');

  const {plantId} = useParams();

  const account = useRecoilValue<AccountRes | null>(accountAtom);

  const {data: meterValues, refetch: refetchMeterValues} = useMeterValues(plantId ?? null, account?.id ?? null);

  const isBalancePeriodValid = isValidBalancePeriod(dateAndTime);
  const isMeterValueValid = isNonNegativeInt(meterValue);

  async function submitMeterValue() {
    if(plantId === undefined) {
      console.error('plant ID is undefined');
      return;
    }

    const url = SET_METER_VALUES.replace('{plantId}', plantId);
    const body: MeterValuePostReq = {
      balance_period: getBalancePeriod(dateAndTime),
      amount_in_kwh: parseInt(meterValue),
    };
    const result = await queryApi<ConfirmationRes>(url, account?.id, 'post', body);
    if(result?.status !== 'ok') {
      console.error('did not get status ok');
      return;
    }

    setMeterValue('');
    await refetchMeterValues();
  }

  return <>
    <Box sx={{m: 5}}>
      <Stack direction="row" sx={{width: '100%'}} spacing={2} justifyContent="space-between" alignItems="center">
        <Typography
          sx={{my: 5}}
          variant="h5"
          id="tableTitle"
          component="div"
        >
          {`${meterValues?.plant_type === 'GENERATION_PLANT' ? 'Erzeugung' : 'Verbrauch'} von Anlage ${meterValues?.plant_name}`}
        </Typography>
        <Stack direction="row" sx={{width: '70%'}} spacing={2} justifyContent="flex-end" alignItems="center">
          {account?.type === 'METERING_POINT_OPERATOR' ?
            <Button
              variant="outlined"
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                event.stopPropagation();
                event.preventDefault();
                setIsDocumentationModalOpen(true);
              }}
              sx={{mt: 1, mr: 1, width: '200px'}}
            >
              Messwerte dokumentieren
            </Button> :
            <Button
              variant="outlined"
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                event.stopPropagation();
                event.preventDefault();
                navigate('/plants/' + plantId + '/flows');
              }}
              sx={{mt: 1, mr: 1, width: '200px'}}
            >
              {
                meterValues?.plant_type === 'GENERATION_PLANT' ?
                  'Anlagenscharfe Energieverteilung nachvollziehen' :
                  'Anlagenscharfe Energieherkunft nachvollziehen'
              }
            </Button>
          }
        </Stack>
      </Stack>

      <TableContainer component={Paper} sx={{mt: 5}}>
        <Table sx={{minWidth: 650}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Bilanzperiode</TableCell>
              {account?.type !== 'PUBLIC' &&
                <TableCell align="center">Betrag (in kWh)</TableCell>
              }
              {meterValues?.plant_type === 'CONSUMPTION_PLANT' &&
                <>
                  <TableCell align="left">Grünstrom-Anteil</TableCell>
                  <TableCell align="left">Genutzte Energiearten</TableCell>
                </>
              }
              <TableCell align="center">Transaktions-Hash</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(meterValues?.meter_values ?? []).map((meterValue) => (
              <TableRow
                key={meterValue.balance_period + '-' + meterValue.amount_in_kwh}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
              >
                <TableCell component="th" scope="row">
                  {formatDateAndTime(getDateFromBalancePeriod(meterValue.balance_period))}
                </TableCell>
                {account?.type !== 'PUBLIC' &&
                  <TableCell align="center">{meterValue.amount_in_kwh}</TableCell>
                }
                {meterValues?.plant_type === 'CONSUMPTION_PLANT' &&
                  <>
                    <TableCell align="left">
                      {
                        (() => {
                          if(meterValue.green_energy_share === null)
                            throw new Error('green energy share is null unexpectedly');

                          return formatPercentage(meterValue.green_energy_share);
                        })()
                      }
                    </TableCell>
                    <TableCell align="left">
                      {
                        functionCallOrDefault(meterValue.generation_type_vectors_union,
                          formatGenerationTypes, '')
                      }
                    </TableCell>
                  </>
                }
                <TableCell align="center">
                  {formatAndLinkTxHash(meterValue.tx_hash_meter_value_publishing)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>

    <Modal
      hideBackdrop
      open={isDocumentationModalOpen}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      disableScrollLock={true}
    >
      <Box sx={{...modalStyle, width: 500, maxWidth: 'calc(100% - 32px - 32px)', overflow: 'scroll'}}>
        <h2 id="child-modal-title">Messwerte dokumentieren</h2>
        <form onSubmit={e => {
          e.preventDefault();
          submitMeterValue().catch(console.error);
        }}>
          <Grid container>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                <div style={{display: 'flex'}}>
                  <div style={{margin: 3}}>
                    <DatePicker
                      sx={{width: 180}}
                      value={dateAndTime}
                      onChange={(value) => {
                        if(value === null) return;
                        setDateAndTime(value);
                      }}
                    />
                  </div>
                  <div style={{margin: 3}}>
                    <TimePicker
                      sx={{width: 120}}
                      value={dateAndTime}
                      minutesStep={15}
                      onChange={(value) => {
                        if(value === null) return;
                        setDateAndTime(value);
                      }}
                    />
                  </div>
                </div>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={{...textFieldStyle, width: 150}}
                label="Messwert (kWh)"
                value={meterValue}
                onChange={getSetter(setMeterValue)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                style={buttonStyle}
                variant="contained"
                type="submit"
                disabled={!isBalancePeriodValid || !isMeterValueValid}
              >
                abschicken
              </Button>
            </Grid>
          </Grid>
        </form>
        <div style={{display: 'flex'}}>
          <Button onClick={() => setIsDocumentationModalOpen(false)} style={{marginLeft: 'auto'}}>close</Button>
        </div>
      </Box>
    </Modal>
  </>;
}

import {useState, useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Stack from '@mui/material/Stack';
import {Outlet} from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import InputLabel from '@mui/material/InputLabel';

import {useNavigate} from 'react-router-dom';

import {useRecoilState} from 'recoil';
import {accountAtom} from '../data/atoms';

import {
  AccountRes,
} from '../shared/types';

import {useAccounts} from '../data/queries';
import {DistributionTrigger} from '../components/DistributionTrigger';

/* eslint-disable-next-line max-lines-per-function */
export default function Layout() {

  const navigate = useNavigate();

  const [account, setAccount] = useRecoilState<null | AccountRes>(accountAtom);

  const {data: accounts} = useAccounts(account?.id);

  useEffect(() => {
    if(!account && accounts) {
      setAccount(accounts.find(a => a.type === 'PUBLIC') ?? null);
    }
  }, [account, accounts]);

  const handleChange = (event: SelectChangeEvent) => {
    const account = accounts?.find(u => u.id === event.target.value) ?? null;
    if(account === null) {
      navigate('/');
    }

    switch(account?.type) {
      case 'PUBLIC': {
        navigate('/');
        break;
      }
      case 'ROOT_AUTHORITY': {
        navigate('/authorities');
        break;
      }
      case 'NETWORK_OPERATOR':
      case 'METERING_POINT_OPERATOR': {
        navigate('/plants');
        break;
      }
      case 'GENERATION_PLANT':
      case 'CONSUMPTION_PLANT': {
        navigate('/plants/' + account.id + '/meterValues');
        break;
      }
    }

    setAccount(account);
  };

  const [openMenu, setOpenMenu] = useState(false);

  const list = () => (
    <Box
      sx={{width: 250}}
      role="presentation"
      onClick={() => setOpenMenu(false)}
      onKeyDown={() => setOpenMenu(false)}
    >
      <List>
        {['ROOT_AUTHORITY'].includes(account?.type ?? '') &&
          <ListItem key={'authorities'} disablePadding>
            <ListItemButton onClick={() => navigate('/authorities')}>
              <ListItemIcon>
                <SupervisorAccountIcon />
              </ListItemIcon>
              <ListItemText primary={'Autoritäten-Übersicht'} />
            </ListItemButton>
          </ListItem>
        }
        {['ROOT_AUTHORITY', 'NETWORK_OPERATOR', 'METERING_POINT_OPERATOR', 'PUBLIC'].includes(account?.type ?? '') &&
          <ListItem key={'plants'} disablePadding>
            <ListItemButton onClick={() => navigate('/plants')}>
              <ListItemIcon>
                <SolarPowerIcon />
              </ListItemIcon>
              <ListItemText primary={'Anlagen-Übersicht'} />
            </ListItemButton>
          </ListItem>
        }
      </List>
    </Box>
  );

  return <Box sx={{flexGrow: 1}}>
    <AppBar position="static">
      <Toolbar>
        <Stack direction="row" sx={{width: '100%'}} spacing={2} justifyContent="space-between" alignItems="center">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{mr: 2}}
            onClick={() => {
              setOpenMenu(true);
            }}
          >
            <MenuIcon />
          </IconButton>
          <DistributionTrigger />
          <FormControl sx={{m: 1}} size="small">
            <InputLabel sx={{color: 'white'}}>Rolle auswählen</InputLabel>
            <Select
              labelId="user-select-label"
              id="user-select"
              value={account?.id ?? ''}
              onChange={handleChange}
              sx={{
                color: 'white',
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(228, 219, 233, 0.25)',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(228, 219, 233, 0.25)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(228, 219, 233, 0.25)',
                },
                '.MuiSvgIcon-root ': {
                  fill: 'white !important',
                },
                minWidth: '300px'
              }}
            >
              {(accounts ?? []).map(a => <MenuItem key={a.id} value={a.id}>{a.name + ' - ' + a.type}</MenuItem>)}
            </Select>
          </FormControl>
        </Stack>
      </Toolbar>
    </AppBar>
    <Outlet />
    <Drawer
      anchor={'left'}
      open={openMenu}
      onClose={() => setOpenMenu(false)}
    >
      {list()}
    </Drawer>
  </Box>;
}

export const config = {
  urls: {
    blockExplorerBaseUrl: 'https://mumbai.polygonscan.com',
  },
  presentation: {
    placeholderNilValue: 'unbekannt',
    numberOfCharactersTxHash: 10,
  },
  market: {
    balancePeriodLengthInMinutes: 15, // Must be a unit fraction of 60.
  },
};

import {useEffect} from 'react';
import Layout from './views/Layout';
import NotFound from './views/NotFound';
import AuthorityList from './views/AuthorityList';
import PlantList from './views/PlantList';
import PlantMeterValues from './views/PlantMeterValues';
import PlantSettings from './views/PlantSettings';
import {Routes, Route, useNavigate} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {accountAtom} from './data/atoms';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import {AccountRes} from './shared/types';
import Flows from './views/Flows';

export default function App() {
  const queryClient = new QueryClient();

  const ProtectedRoute = ({children}: {children: JSX.Element}): JSX.Element => {
    const account = useRecoilValue<AccountRes | null>(accountAtom);
    const navigate = useNavigate();

    useEffect(() => {
      if(!account) {
        navigate('/');
      }
    }, [account]);

    return children;
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<PlantList />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/authorities" element={<ProtectedRoute><Layout /></ProtectedRoute>}>
          <Route index element={<ProtectedRoute><AuthorityList /></ProtectedRoute>} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/plants" element={<ProtectedRoute><Layout /></ProtectedRoute>}>
          <Route index element={<ProtectedRoute><PlantList /></ProtectedRoute>} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/plants/:plantId" element={<ProtectedRoute><Layout /></ProtectedRoute>}>
          <Route path="settings" element={<ProtectedRoute><PlantSettings /></ProtectedRoute>} />
          <Route path="meterValues" element={<ProtectedRoute><PlantMeterValues /></ProtectedRoute>} />
          <Route path="flows" element={<ProtectedRoute><Flows /></ProtectedRoute>} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

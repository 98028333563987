import {config} from '../config';
import {formatTxHash} from './textFormatting';

export function formatAndLinkTxHash(txHash: string | null) {
  if(txHash === null)
    return config.presentation.placeholderNilValue;

  const url = `${config.urls.blockExplorerBaseUrl}/tx/${txHash}`;

  return <a href={url} target="_blank">{formatTxHash(txHash)}</a>;
}

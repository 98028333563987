import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import 'dayjs/locale/de';
import {useParams} from 'react-router-dom';

import {useFlows} from '../data/queries';

import {useRecoilValue} from 'recoil';
import {accountAtom} from '../data/atoms';

import {AccountRes, Polygon} from '../shared/types';
import {getDateFromBalancePeriod} from '../utils/dateAndTime';
import {formatArray, formatCircuitVector, formatDateAndTime, formatGenerationTypeVector, formatKilometers, formatPercentage} from '../utils/textFormatting';
import {formatAndLinkTxHash} from '../utils/linkFormatting';
import {getAveragePointOfMostExtremeCoordinates, getDistanceBetweenPoints} from '../utils/geometry';
import {isGreenEnergyVector} from '../shared/functions';
import {getPolygonAsMap} from '../utils/maps';

/* eslint-disable-next-line max-lines-per-function */
export default function Flows() {
  const {plantId} = useParams();

  const account = useRecoilValue<AccountRes | null>(accountAtom);

  const {data: flows} = useFlows(plantId ?? null, account?.id ?? null);

  return <>
    <Box sx={{m: 5}}>
      <Stack direction="row" sx={{width: '100%'}} spacing={2} justifyContent="space-between" alignItems="center">
        <Typography
          sx={{my: 5}}
          variant="h5"
          id="tableTitle"
          component="div"
        >
          {
            flows?.plant_type === 'GENERATION_PLANT' ?
              `Von Erzeugungsanlage ${flows?.plant_name} belieferte Verbrauchsanlagen` :
              `Durch Erzeugungsanlagen gedeckter Verbrauch von Verbrauchsanlage ${flows?.plant_name}`
          }
        </Typography>
      </Stack>

      <TableContainer component={Paper} sx={{mt: 5}}>
        <Table sx={{minWidth: 650}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Bilanzperiode</TableCell>
              {account?.type !== 'PUBLIC' &&
                <TableCell align="center">Betrag (in kWh)</TableCell>
              }
              <TableCell align="center">Anteil am Verbrauch</TableCell>
              <TableCell align="center">Name der Anlage</TableCell>
              {flows?.plant_type === 'CONSUMPTION_PLANT' &&
                <TableCell align="center">Grünstrom</TableCell>
              }
              <TableCell align="center">
                Worst-Case-Entfernung<br />
                (Ø-Entfernung)
              </TableCell>
              <TableCell align="center">Netzstrangvektoren</TableCell>
              {flows?.plant_type === 'CONSUMPTION_PLANT' &&
                <TableCell align="center">Erzeugungsart-Vektoren</TableCell>
              }
              <TableCell align="center">Polygone</TableCell>
              <TableCell align="center">Transaktions-Hash</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(flows?.flows ?? []).map((flow) => (
              <TableRow
                key={flow.balance_period + '-' + flow.amount_in_kwh}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
              >
                <TableCell component="th" scope="row">
                  {formatDateAndTime(getDateFromBalancePeriod(flow.balance_period))}
                </TableCell>
                {account?.type !== 'PUBLIC' &&
                  <TableCell align="center">{flow.amount_in_kwh}</TableCell>
                }
                <TableCell align="center">{formatPercentage(flow.share_of_consumption)}</TableCell>
                <TableCell align="center">{flow.other_plant.name}</TableCell>
                {flows?.plant_type === 'CONSUMPTION_PLANT' &&
                  <TableCell align="center">
                    {
                      flow.other_plant.generation_type_vectors
                        .some(gtv => isGreenEnergyVector(gtv.generation_types)) ?
                        <CheckIcon /> :
                        <ClearIcon />
                    }
                  </TableCell>
                }
                <TableCell align="center">
                  {
                    (() => {
                      const geoPointsA = flows?.plant_polygons.map(pM => pM.geo_points) ?? [];
                      const geoPointsB = flow.other_plant.polygons.map(pM => pM.geo_points);
                      const expectedDistance = getDistanceBetweenPolygons(geoPointsA, geoPointsB);
                      const worstCaseDistance = getMinMaxDistanceBetweenPolygons(geoPointsA, geoPointsB);
                      if(expectedDistance === null || worstCaseDistance === null)
                        return '';

                      return <>
                        {formatKilometers(worstCaseDistance)}<br />
                        ({formatKilometers(expectedDistance)})
                      </>;
                    })()
                  }
                </TableCell>
                <TableCell align="center">
                  {formatArray(flow.other_plant.circuit_vectors, formatCircuitVector)}
                </TableCell>
                {flows?.plant_type === 'CONSUMPTION_PLANT' &&
                  <TableCell align="center">
                    {formatArray(flow.other_plant.generation_type_vectors, formatGenerationTypeVector)}
                  </TableCell>
                }
                <TableCell align="center">
                  {flow.other_plant.polygons.map(p => getPolygonAsMap(p.geo_points))}
                </TableCell>
                <TableCell align="center">
                  Dokumentation der Erzeugung: {formatAndLinkTxHash(flow.tx_hash_generation_documentation)}<br />
                  Dokumentation des Verbrauchs: {formatAndLinkTxHash(flow.tx_hash_consumption_documentation)}<br />
                  Flow Splits: {flow.tx_hashes_flow_splits.map((txHash, i, arr) =>
                    <>
                      {formatAndLinkTxHash(txHash)}
                      {i === arr.length - 1 ? '' : ', '}
                    </>)}

                  {flows?.plant_type === 'CONSUMPTION_PLANT' &&
                    <>
                      <br />
                      Consumption Proof: {formatAndLinkTxHash(flow.tx_hash_consumption_proof)}
                    </>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  </>;
}

function getDistanceBetweenPolygons(aArr: Polygon[], bArr: Polygon[]) {
  const centerPointsA = aArr.map(getAveragePointOfMostExtremeCoordinates);
  const centerPointsB = bArr.map(getAveragePointOfMostExtremeCoordinates);

  const distances = centerPointsA.flatMap(cA => centerPointsB.map(cB => getDistanceBetweenPoints(cA, cB)));
  if(distances.length === 0)
    return null;

  return Math.min(...distances);
}

function getMinMaxDistanceBetweenPolygons(aArr: Polygon[], bArr: Polygon[]) {
  const pairs = aArr.flatMap(a => bArr.map(b => ({a, b})));
  const maxDistances = pairs.map(({a, b}) => getMaxDistanceBetweenPolygons(a, b))
    .filter((d): d is number => d !== null);

  if(maxDistances.length === 0)
    return null;

  return Math.min(...maxDistances);
}

function getMaxDistanceBetweenPolygons(aPolygon: Polygon, bPolygon: Polygon) {
  const pairs = aPolygon.flatMap(a => bPolygon.map(b => ({a, b})));
  const distances = pairs.map(({a, b}) => getDistanceBetweenPoints(a, b));

  if(distances.length === 0)
    return null;

  return Math.max(...distances);
}

import {
  AccountRes,
  AUTHORITY_TYPE,
  PLANT_TYPE,
} from '../shared/types';

const checker = (arr: (AUTHORITY_TYPE | PLANT_TYPE)[], target: (AUTHORITY_TYPE | PLANT_TYPE)[]) => target.every(v => arr.includes(v));

export function canAdd(roles: (AUTHORITY_TYPE | PLANT_TYPE)[], account: AccountRes | null) {
  if(account === null) return false;

  if(checker(['ROOT_AUTHORITY', 'NETWORK_OPERATOR', 'METERING_POINT_OPERATOR'], roles)) {
    return account.type === 'ROOT_AUTHORITY';
  }

  if(checker(['GENERATION_PLANT', 'CONSUMPTION_PLANT'], roles)) {
    return account.type === 'NETWORK_OPERATOR';
  }

  return false;
}


import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import {RecoilRoot} from 'recoil';

const rootElement = document.getElementById('root');
if(rootElement === null) {
  throw new Error('Root element not found.');
}
const root = createRoot(rootElement);
root.render(
  <BrowserRouter>
    <RecoilRoot>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </RecoilRoot>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
/* eslint-disable-next-line @typescript-eslint/no-floating-promises */
reportWebVitals();

import {useQuery} from 'react-query';
import {
  DEFAULT_HEADER, GET_ACCOUNTS, GET_AUTHORITIES, GET_PLANTS, GET_METER_VALUES, GET_PLANT, GET_FLOWS, GET_CIRCUITS,
} from './endpoints';

import axios, {AxiosResponse} from 'axios';

import {
  AccountRes,
  AuthorityRes,
  CircuitRes,
  MetaFlowRes,
  MetaMeterValueRes,
  PlantResPrivate,
  UUID,
} from '../shared/types';

export const useAccounts = (accountId?: string | null) => {
  return useQuery(
    ['accounts'],
    async (): Promise<AccountRes[]> => {
      const response: AxiosResponse<AccountRes[]> = await axios.get(
        GET_ACCOUNTS, {
          headers: accountId === undefined ?
            DEFAULT_HEADER :
            {...DEFAULT_HEADER, 'X-Account-Id': accountId ?? ''}
        });
      return response.data;
    }
  );
};

export const useAuthorities = (accountId?: string | null) => {
  return useQuery(
    ['authorities'],
    async (): Promise<AuthorityRes[]> => {
      const response: AxiosResponse<AuthorityRes[]> = await axios.get(
        GET_AUTHORITIES, {
          headers: accountId === undefined ?
            DEFAULT_HEADER :
            {...DEFAULT_HEADER, 'X-Account-Id': accountId ?? ''}
        });
      return response.data;
    }
  );
};

export const usePlants = (accountId?: string | null) => {
  return useQuery(
    ['plants'],
    async (): Promise<PlantResPrivate[]> => {
      const response: AxiosResponse<PlantResPrivate[]> = await axios.get(
        GET_PLANTS, {
          headers: accountId === undefined ?
            DEFAULT_HEADER :
            {...DEFAULT_HEADER, 'X-Account-Id': accountId ?? ''}
        });
      return response.data;
    }
  );
};

export const useCircuits = (accountId?: string | null) => {
  return useQuery(
    ['circuits'],
    async (): Promise<CircuitRes[]> => {
      const response: AxiosResponse<CircuitRes[]> = await axios.get(
        GET_CIRCUITS, {
          headers: accountId === undefined ?
            DEFAULT_HEADER :
            {...DEFAULT_HEADER, 'X-Account-Id': accountId ?? ''}
        });
      return response.data;
    }
  );
};

export const usePlant = (accountId: string | null, plantId: UUID) => {
  return useQuery(
    ['plantByPlantId', plantId],
    async (): Promise<PlantResPrivate> => {
      const response: AxiosResponse<PlantResPrivate> = await axios.get(
        GET_PLANT.replace('{plantId}', plantId), {
          headers: accountId === undefined ?
            DEFAULT_HEADER :
            {...DEFAULT_HEADER, 'X-Account-Id': accountId ?? ''}
        });
      return response.data;
    }
  );
};

export const useMeterValues = (plantId: string | null, accountId?: string | null) => {
  return useQuery(
    ['meterValuesByPlantId', plantId],
    async (): Promise<MetaMeterValueRes> => {
      const response: AxiosResponse<MetaMeterValueRes> = await axios.get(
        GET_METER_VALUES.replace('{plantId}', plantId ?? ''), {
          headers: accountId === undefined ?
            DEFAULT_HEADER :
            {...DEFAULT_HEADER, 'X-Account-Id': accountId ?? ''}
        });
      return response.data;
    },
    {
      enabled: plantId !== null
    }
  );
};

export const useFlows = (plantId: string | null, accountId?: string | null) => {
  return useQuery(
    ['flowsByPlantId', plantId],
    async (): Promise<MetaFlowRes> => {
      const response: AxiosResponse<MetaFlowRes> = await axios.get(
        GET_FLOWS.replace('{plantId}', plantId ?? ''), {
          headers: accountId === undefined ?
            DEFAULT_HEADER :
            {...DEFAULT_HEADER, 'X-Account-Id': accountId ?? ''}
        });
      return response.data;
    },
    {
      enabled: plantId !== null
    }
  );
};

import {useState} from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {Button, Grid, Modal, TextField} from '@mui/material';
import Stack from '@mui/material/Stack';

import {useAccounts, useAuthorities} from '../data/queries';

import {useRecoilValue} from 'recoil';
import {accountAtom} from '../data/atoms';
import {canAdd} from '../utils/roles';

import {
  AccountRes,
  AUTHORITY_TYPE,
  AuthorityPostReq,
  ConfirmationRes
} from '../shared/types';
import {SET_AUTHORITIES} from '../data/endpoints';
import {assertNever, getSetter, queryApi} from '../utils/misc';
import {buttonStyle, modalStyle, textFieldStyle} from '../style';
import {formatAndLinkTxHash} from '../utils/linkFormatting';

const authorityTypes =
  ['ROOT_AUTHORITY', 'NETWORK_OPERATOR', 'METERING_POINT_OPERATOR'] satisfies AUTHORITY_TYPE[];

/* eslint-disable-next-line max-lines-per-function */
export default function AuthorityList() {
  const [authorityType, setAuthorityType] = useState<AUTHORITY_TYPE | null>(null);
  const [authorityName, setAuthorityName] = useState('');

  const account = useRecoilValue<AccountRes | null>(accountAtom);
  const {refetch: refetchAccounts} = useAccounts(account?.id);

  const {data: authorities, refetch: refetchAuthorities} = useAuthorities(account?.id ?? null);

  const isAuthorityNameValid = authorityName.length > 0;

  async function submitAuthority() {
    if(authorityType === null)
      throw new Error('authority type is null');

    const url = SET_AUTHORITIES;
    const body: AuthorityPostReq = {
      type: authorityType,
      name: authorityName,
    };
    const result = await queryApi<ConfirmationRes>(url, account?.id, 'post', body);

    if(result?.status !== 'ok') {
      console.error('did not get status ok');
      return;
    }

    setAuthorityName('');

    await refetchAuthorities();
    await refetchAccounts();
  }

  return <>
    <Box sx={{m: 5}}>
      <Stack direction="row" sx={{width: '100%'}} spacing={2} justifyContent="space-between" alignItems="center">
        <Typography
          sx={{my: 5}}
          variant="h5"
          id="tableTitle"
          component="div"
        >
          Autoritäten
        </Typography>
        <Stack direction="row" sx={{width: '100%'}} spacing={2} justifyContent="flex-end" alignItems="center">
          {canAdd(authorityTypes, account) &&
            authorityTypes.map(at =>
              <Button
                key={at}
                variant="outlined"
                onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  event.stopPropagation();
                  event.preventDefault();
                  setAuthorityType(at);
                }}
                sx={{mt: 1, mr: 1, width: '200px'}}
              >
                {`${authorityTypeToString(at)} hinzufügen`}
              </Button>
            )
          }
        </Stack>
      </Stack>
      <TableContainer component={Paper} sx={{mt: 5}}>
        <Table sx={{minWidth: 650}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="center">Address</TableCell>
              <TableCell align="center">Typ</TableCell>
              <TableCell align="center">Transaktions-Hashes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(authorities ?? []).map((authority) => (
              <TableRow
                key={authority.name}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
              >
                <TableCell component="th" scope="row">
                  {authority.name}
                </TableCell>
                <TableCell align="center">{authority.address}</TableCell>
                <TableCell align="center">{authorityTypeToString(authority.type)}</TableCell>
                <TableCell align="center">
                  Rollen-Vergabe: {formatAndLinkTxHash(authority.tx_hash_role_assignment)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>

    {authorityType !== null &&

      <Modal
        hideBackdrop
        open={true}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        disableScrollLock={true}
      >
        <Box sx={{...modalStyle, width: 500, maxWidth: 'calc(100% - 32px - 32px)', overflow: 'scroll'}}>
          <h2 id="child-modal-title">{`${authorityTypeToString(authorityType)} hinzufügen`}</h2>
          <form onSubmit={e => {
            e.preventDefault();
            submitAuthority().catch(console.error);
          }}>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  style={{...textFieldStyle, width: 150}}
                  label="Autoritätsname"
                  value={authorityName}
                  onChange={getSetter(setAuthorityName)}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  style={buttonStyle}
                  variant="contained"
                  type="submit"
                  disabled={!isAuthorityNameValid}
                >
                  abschicken
                </Button>
              </Grid>
            </Grid>
          </form>
          <div style={{display: 'flex'}}>
            <Button onClick={() => setAuthorityType(null)} style={{marginLeft: 'auto'}}>close</Button>
          </div>
        </Box>
      </Modal>
    }
  </>;
}

function authorityTypeToString(authorityType: AUTHORITY_TYPE) {
  switch(authorityType) {
    case 'ROOT_AUTHORITY':
      return 'Root-Autorität';
    case 'NETWORK_OPERATOR':
      return 'Netzbetreiber';
    case 'METERING_POINT_OPERATOR':
      return 'Messstellenbetreiber';
    default:
      assertNever(authorityType);
  }
}

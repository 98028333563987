import { GenerationTypeEnum } from './types';
import { nonGreenEnergyTypes } from './config';

export function isGreenEnergyVector(generationTypes: GenerationTypeEnum[]) {
  return !generationTypes.some(isNonGreenEnergyGenerationType);
}

function isNonGreenEnergyGenerationType(generationType: GenerationTypeEnum) {
  return nonGreenEnergyTypes.includes(generationType);
}

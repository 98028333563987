import {config} from '../config';
import {CircuitVectorRes, GenerationTypeEnum, GenerationTypeVectorRes, GeoPoint, Polygon} from '../shared/types';

export function formatArray<T>(arr: T[], elementToString: (arg0: T) => string) {
  if(arr.length === 0)
    return '[]';

  const stringifiedElements = arr.map(elementToString);
  const stringifiedList = stringifiedElements.reduce((prev, curr) => `${prev}, ${curr}`);

  return `[${stringifiedList}]`;
}

export function formatCircuitVector(circuitVector: CircuitVectorRes) {
  return formatCircuits(circuitVector.circuits);
}

export function formatCircuits(circuits: CircuitVectorRes['circuits']) {
  return formatArray(circuits, x => x.name);
}

export function formatGenerationTypeVector(generationTypeVector: GenerationTypeVectorRes) {
  return formatGenerationTypes(generationTypeVector.generation_types);
}

export function formatGenerationTypes(generationTypes: GenerationTypeEnum[]) {
  return formatArray(generationTypes, x => x);
}

export function formatPolygon(polygon: {geo_points: Polygon}) {
  return formatArray(polygon.geo_points, formatGeoPoint);
}

export function formatGeoPoint(geoPoint: GeoPoint) {
  return `(${geoPoint.lat}, ${geoPoint.lng})`;
}

export function formatDateAndTime(date: Date) {
  return date.toLocaleString('de', {hour: 'numeric', minute: 'numeric', day: 'numeric', month: 'long', year: 'numeric'});
}

export function formatTxHash(txHash: string | null) {
  if(txHash === null)
    return config.presentation.placeholderNilValue;

  if(config.presentation.numberOfCharactersTxHash >= 42)
    return txHash;

  return `${txHash.slice(0, config.presentation.numberOfCharactersTxHash)}...`;
}

export function formatKilometers(m: number, decimals = 2) {
  return (m / 1000).toFixed(decimals) + ' km';
}

export function functionCallOrDefault<T, D>(v: T | null | undefined, f: (arg0: T) => D, d: D): D {
  if(v === null || v === undefined)
    return d;

  return f(v);
}

export function formatPercentage(share: number, decimals = 2) {
  return `${(share * 100).toFixed(decimals)}%`;
}

const ENV_CONFIG: {API_URL?: string} = (window as Window & typeof globalThis & {'_env_': Record<string, string>})._env_ ?? {};

const SERVER_ENDPOINT: string = ENV_CONFIG.API_URL ?? 'http://localhost:8080/api';

export const GET_ACCOUNTS: string = SERVER_ENDPOINT + '/accounts';
export const GET_AUTHORITIES: string = SERVER_ENDPOINT + '/authorities';
export const SET_AUTHORITIES: string = SERVER_ENDPOINT + '/authorities';
export const GET_PLANTS: string = SERVER_ENDPOINT + '/plants';
export const SET_PLANTS: string = SERVER_ENDPOINT + '/plants';
export const GET_CIRCUITS: string = SERVER_ENDPOINT + '/plants/circuits';
export const GET_PLANT: string = SERVER_ENDPOINT + '/plants/{plantId}';
export const GET_METER_VALUES: string = SERVER_ENDPOINT + '/plants/{plantId}/meter-values';
export const SET_METER_VALUES: string = SERVER_ENDPOINT + '/plants/{plantId}/meter-values';
export const GET_FLOWS: string = SERVER_ENDPOINT + '/plants/{plantId}/flows';
export const SET_CIRCUIT_VECTORS: string = SERVER_ENDPOINT + '/plants/{plantId}/circuit-vectors';
export const SET_GENERATION_TYPE_VECTORS: string = SERVER_ENDPOINT + '/plants/{plantId}/generation-type-vectors';
export const SET_POLYGONS: string = SERVER_ENDPOINT + '/plants/{plantId}/polygons';
export const PERFORM_DISTRIBUTION: string = SERVER_ENDPOINT + '/perform-distribution';

export const DEFAULT_HEADER = {
  'accept': 'application/vnd.origin.api.v1+json',
  'content-type': 'application/json',
};

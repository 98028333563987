import {Polygon} from '../shared/types';
import {encode} from '@googlemaps/polyline-codec';
import {getClosedPolygon} from './geometry';
import {formatPolygon} from './textFormatting';

const GOOGLE_MAPS_API_KEY = 'AIzaSyBuUhbVqwWYH2H6m7apmTqsj7bJjrQy2_U'; // TODO: turn into env variable

function getPolygonMiniMapUrl(polygon: Polygon) {
  const apiKey = GOOGLE_MAPS_API_KEY;
  const pathEncoded = encode(getClosedPolygon(polygon), 5);

  return 'https://maps.googleapis.com/maps/api/staticmap?size=200x200&' +
    `key=${apiKey}&path=enc:${pathEncoded}`;
}

function getPolygonSatelliteMapUrl(polygon: Polygon) {
  const apiKey = GOOGLE_MAPS_API_KEY;
  const pathEncoded = encode(getClosedPolygon(polygon), 5);

  return 'https://maps.googleapis.com/maps/api/staticmap?size=9999x9999&scale=2&' +
    `key=${apiKey}&path=enc:${pathEncoded}&maptype=satellite`;
}

export function getPolygonAsMap(polygon: Polygon) {
  const polygonAsText = formatPolygon({geo_points: polygon});

  return <>
    <a href={getPolygonSatelliteMapUrl(polygon)} target="_blank" >
      <img src={getPolygonMiniMapUrl(polygon)} title={polygonAsText} alt={polygonAsText} />
    </a>
  </>;
}

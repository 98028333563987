import {useState} from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import {useNavigate} from 'react-router-dom';
import {useAccounts, usePlants} from '../data/queries';
import {useRecoilValue} from 'recoil';
import {accountAtom} from '../data/atoms';
import {canAdd} from '../utils/roles';
import {
  AccountRes, ConfirmationRes, GenerationTypeEnum, PLANT_TYPE, PlantPostReq, PlantTypeEnumRestricted,
} from '../shared/types';
import {buttonStyle, modalStyle, textFieldStyle} from '../style';
import {assertNever, getSetter, queryApi} from '../utils/misc';
import {SET_PLANTS} from '../data/endpoints';
import {isFloat} from '../utils/stringValidators';
import {formatArray, formatCircuitVector, formatGenerationTypeVector} from '../utils/textFormatting';
import {formatAndLinkTxHash} from '../utils/linkFormatting';
import {getPolygonAsMap} from '../utils/maps';

/* eslint-disable-next-line max-lines-per-function */
export default function PlantList() {
  const [plantType, setPlantType] = useState<PLANT_TYPE | null>(null);
  const [plantName, setPlantName] = useState('');
  const [circuitName, setCircuitName] = useState('');
  const [plantGenerationType, setPlantGenerationType] = useState<GenerationTypeEnum | null>(null);
  const [plantLatitude, setPlantLatitude] = useState('');
  const [plantLongitude, setPlantLongitude] = useState('');

  const navigate = useNavigate();

  const account = useRecoilValue<AccountRes | null>(accountAtom);
  const {refetch: refetchAccounts} = useAccounts(account?.id);

  const {data: plants, refetch: refetchPlants} = usePlants(account?.id ?? null);

  const isPlantNameValid = plantName.length > 0;
  const isPlantLatitudeValid = isFloat(plantLatitude);
  const isPlantLongitudeValid = isFloat(plantLongitude);

  async function submitPlant() {
    if(plantType === null)
      throw new Error('plant type is null');

    const url = SET_PLANTS;
    const body: PlantPostReq = {
      type: plantType,
      name: plantName,
      circuit_name: circuitName,
      generation_type: plantGenerationType,
      location: {lat: parseFloat(plantLatitude), lng: parseFloat(plantLongitude)},
    };
    const result = await queryApi<ConfirmationRes>(url, account?.id, 'post', body);

    if(result?.status !== 'ok') {
      console.error('did not get status ok');
      return;
    }

    setPlantName('');
    setCircuitName('');
    setPlantLatitude('');
    setPlantLongitude('');

    await refetchPlants();
    await refetchAccounts();
  }

  const showExactInformation =
    account?.type === 'NETWORK_OPERATOR' || account?.type === 'METERING_POINT_OPERATOR';

  return <>
    <Box sx={{m: 5}}>
      <Stack direction="row" sx={{width: '100%'}} spacing={2} justifyContent="space-between" alignItems="center">
        <Typography
          sx={{my: 5}}
          variant="h5"
          id="tableTitle"
          component="div"
        >
          Anlagen
        </Typography>
        <Stack direction="row" sx={{width: '100%'}} spacing={2} justifyContent="flex-end" alignItems="center">
          {canAdd(['GENERATION_PLANT'], account) &&
            <Button
              variant="outlined"
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                event.stopPropagation();
                event.preventDefault();
                setPlantType(PlantTypeEnumRestricted.GENERATION_PLANT);
                setPlantGenerationType(GenerationTypeEnum.BIO_GAS);
              }}
              sx={{mt: 1, mr: 1, width: '200px'}}
            >
              Erzeugungsanlage hinzufügen
            </Button>
          }
          {canAdd(['CONSUMPTION_PLANT'], account) &&
            <Button
              variant="outlined"
              onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                event.stopPropagation();
                event.preventDefault();
                setPlantType(PlantTypeEnumRestricted.CONSUMPTION_PLANT);
                setPlantGenerationType(null);
              }}
              sx={{mt: 1, mr: 1, width: '200px'}}
            >
              Verbrauchsanlage hinzufügen
            </Button>
          }
        </Stack>
      </Stack>
      <TableContainer component={Paper} sx={{mt: 5}}>
        <Table sx={{minWidth: 650}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="center">Adresse</TableCell>
              <TableCell align="center">Typ</TableCell>
              <TableCell align="center">
                {showExactInformation ? 'Netzstrang' : 'Netzstrangvektoren'}
              </TableCell>
              <TableCell align="center">
                {showExactInformation ? 'Erzeugungsart' : 'Energieartvektoren'}
              </TableCell>
              <TableCell align="center">
                {showExactInformation ? 'Standort' : 'Polygone'}
              </TableCell>
              <TableCell align="center">Transaktions-Hashes</TableCell>
              <TableCell align="right">Aktionen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(plants ?? []).map((plant, i) => (
              <TableRow
                key={i}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
              >
                <TableCell component="th" scope="row">
                  {plant.name}
                </TableCell>
                <TableCell align="center">{plant.address}</TableCell>
                <TableCell align="center">{plantTypeToString(plant.type)}</TableCell>
                <TableCell align="center">
                  {
                    showExactInformation ?
                      plant.circuit_name :
                      formatArray(plant.circuit_vectors, formatCircuitVector)
                  }
                </TableCell>
                <TableCell align="center">
                  {
                    showExactInformation ?
                      plant.generation_type :
                      (plant.type === 'CONSUMPTION_PLANT' ? '' :
                        formatArray(plant.generation_type_vectors, formatGenerationTypeVector))
                  }
                </TableCell>
                <TableCell align="center">
                  {
                    showExactInformation ?
                      getPolygonAsMap([plant.location]) :
                      plant.polygons.map(p => getPolygonAsMap(p.geo_points))
                  }
                </TableCell>
                <TableCell align="center">
                  Rollen-Vergabe: {formatAndLinkTxHash(plant.tx_hash_role_assignment)}<br />
                  Veröffentlichung des Public Keys: {formatAndLinkTxHash(plant.tx_hash_public_key_publishing)}<br />
                  Veröffentlichung der Commitments: {formatAndLinkTxHash(plant.tx_hash_commitments_publishing)}
                </TableCell>
                <TableCell align="right">{
                  <>
                    {account?.type === 'NETWORK_OPERATOR' &&
                      <Button
                        variant="outlined"
                        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          event.stopPropagation();
                          event.preventDefault();
                          navigate('/plants/' + plant.id + '/settings');
                        }}
                        sx={{mt: 1, mr: 1, width: '250px'}}
                      >
                        Anlageneigenschaften konfigurieren
                      </Button>
                    }
                    {account?.type === 'METERING_POINT_OPERATOR' &&
                      <Button
                        variant="outlined"
                        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          event.stopPropagation();
                          event.preventDefault();
                          navigate('/plants/' + plant.id + '/meterValues');
                        }}
                        sx={{mt: 1, mr: 1, width: '200px'}}
                      >
                        Messwerte verwalten
                      </Button>
                    }
                    {account?.type === 'PUBLIC' &&
                      <Button
                        variant="outlined"
                        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          event.stopPropagation();
                          event.preventDefault();
                          navigate('/plants/' + plant.id + '/meterValues');
                        }}
                        sx={{mt: 1, mr: 1, width: '230px'}}
                      >
                        {
                          plant.type === 'CONSUMPTION_PLANT' ?
                            'Energieherkunft nachvollziehen' :
                            'Energieverteilung nachvollziehen'
                        }
                      </Button>
                    }
                  </>
                }</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>

    {plantType !== null &&
      <Modal
        hideBackdrop
        open={true}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        disableScrollLock={true}
      >
        <Box sx={{...modalStyle, width: 500, maxWidth: 'calc(100% - 32px - 32px)', overflow: 'scroll'}}>
          <h2 id="child-modal-title">{`${plantTypeToString(plantType)} hinzufügen`}</h2>
          <form onSubmit={e => {
            e.preventDefault();
            submitPlant().catch(console.error);
          }}>
            <Grid container>
              <Grid item xs={12}>
                <div style={{display: 'flex'}}>
                  <TextField
                    style={{...textFieldStyle, width: 150}}
                    label="Anlagenname"
                    value={plantName}
                    onChange={getSetter(setPlantName)}
                  />

                  <TextField
                    style={{...textFieldStyle, width: 150}}
                    label="Netzstrang"
                    value={circuitName}
                    onChange={getSetter(setCircuitName)}
                  />
                </div>
              </Grid>
              {plantGenerationType !== null &&
                <Grid item xs={12}>
                  <div style={{display: 'flex'}}>
                    <div style={{...textFieldStyle}}>
                      <FormControl fullWidth>
                        <InputLabel id="generation-type-label">Erzeugungsart</InputLabel>
                        <Select
                          id="generation-type-select"
                          labelId="generation-type-label"
                          style={{width: 300 + 2 * textFieldStyle.margin}}
                          value={plantGenerationType}
                          label="Erzeugungsart"
                          onChange={e => {
                            const value = Object.values(GenerationTypeEnum)
                              .find(generationType => generationType === e.target.value);

                            if(value === undefined)
                              throw new Error('received unexpected event target value');

                            setPlantGenerationType(value);
                          }}
                        >
                          {Object.values(GenerationTypeEnum).map(generationType =>
                            <MenuItem key={generationType} value={generationType}>{generationType}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </Grid>
              }
              <Grid item xs={12}>
                <TextField
                  style={{...textFieldStyle, width: 150}}
                  label="Breitengrad"
                  value={plantLatitude}
                  onChange={getSetter(setPlantLatitude)}
                />

                <TextField
                  style={{...textFieldStyle, width: 150}}
                  label="Längengrad"
                  value={plantLongitude}
                  onChange={getSetter(setPlantLongitude)}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  style={buttonStyle}
                  variant="contained"
                  type="submit"
                  disabled={!isPlantNameValid || !isPlantLatitudeValid || !isPlantLongitudeValid}
                >
                  abschicken
                </Button>
              </Grid>
            </Grid>
          </form>
          <div style={{display: 'flex'}}>
            <Button onClick={() => setPlantType(null)} style={{marginLeft: 'auto'}}>close</Button>
          </div>
        </Box>
      </Modal>
    }
  </>;
}

function plantTypeToString(plantType: PLANT_TYPE) {
  switch(plantType) {
    case 'CONSUMPTION_PLANT':
      return 'Verbrauchsanlage';
    case 'GENERATION_PLANT':
      return 'Erzeugungsanlage';
    default:
      assertNever(plantType);
  }
}

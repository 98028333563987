import dayjs from 'dayjs';
import {config} from '../config';

export function getCurrentBalancePeriod() {
  const currentMinute = dayjs().minute();
  const minute = roundToStep(currentMinute, config.market.balancePeriodLengthInMinutes);

  return dayjs().minute(minute).second(0);
}
function roundToStep(x: number, stepSize: number) {
  return Math.floor(x / stepSize) * stepSize;
}
const secondsPerMinute = 60;

export function getBalancePeriod(dayJsDate: dayjs.Dayjs) {
  const timestamp = dayJsDate.unix();
  const balancePeriod = timestamp / secondsPerMinute / config.market.balancePeriodLengthInMinutes;
  if(!Number.isInteger(balancePeriod))
    throw new Error('date does not describe valid balance period');

  return balancePeriod;
}

export function isValidBalancePeriod(dayJsDate: dayjs.Dayjs) {
  const timestamp = dayJsDate.unix();

  return timestamp % (secondsPerMinute * config.market.balancePeriodLengthInMinutes) === 0;
}

export function getDateFromBalancePeriod(balancePeriod: number) {
  const timestamp = balancePeriod * secondsPerMinute * config.market.balancePeriodLengthInMinutes;
  return new Date(timestamp * 1000);
}

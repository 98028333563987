import {DatePicker, LocalizationProvider, TimePicker} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {useState} from 'react';
import {getBalancePeriod, getCurrentBalancePeriod} from '../utils/dateAndTime';
import {Box, Button} from '@mui/material';
import {PERFORM_DISTRIBUTION} from '../data/endpoints';
import {ConfirmationRes, DistributionPostReq} from '../shared/types';
import {queryApi} from '../utils/misc';

export function DistributionTrigger() {
  const [dateAndTime, setDateAndTime] = useState(getCurrentBalancePeriod());

  async function submitMeterValue() {
    const url = PERFORM_DISTRIBUTION;
    const body: DistributionPostReq = {
      balance_period: getBalancePeriod(dateAndTime),
    };
    const result = await queryApi<ConfirmationRes>(url, undefined, 'post', body);
    if(result?.status !== 'ok') {
      console.error('did not get status ok');
      return;
    }
  }

  return <Box sx={{display: 'flex'}}>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
      <div style={{display: 'flex'}}>
        <div style={{margin: 3}}>
          <DatePicker
            sx={{
              width: 180,
              color: 'white!important',
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
              },
              '.MuiInputBase-input': {
                color: 'white!important',
              },
              '.MuiSvgIcon-root ': {
                fill: 'white!important',
              },
            }}
            value={dateAndTime}
            onChange={(value) => {
              if(value === null) return;
              setDateAndTime(value);
            }}
          />
        </div>
        <div style={{margin: 3}}>
          <TimePicker
            sx={{
              width: 120,
              color: 'white!important',
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
              },
              '.MuiInputBase-input': {
                color: 'white!important',
              },
              '.MuiSvgIcon-root ': {
                fill: 'white!important',
              },
            }}
            value={dateAndTime}
            minutesStep={15}
            onChange={(value) => {
              if(value === null) return;
              setDateAndTime(value);
            }}
          />
        </div>
      </div>
    </LocalizationProvider>
    <Button
      sx={{color: 'white'}}
      variant="outlined"
      onClick={() => submitMeterValue()}
    >
      Verteilung anstoßen
    </Button>
  </Box>;
}

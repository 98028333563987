import {Polygon} from '../shared/types';

const nonNegativeIntRegex = /^\d+$/;
const floatRegexStrUnlimited = '[+-]?\\d+(\\.\\d+)?';
const floatRegexStr = `^${floatRegexStrUnlimited}$`;

// Whitespace is not considered in the geo point and polygon regexes
// because it can simply be removed before the regexes are applied.
const geoPointStrUnlimited = `\\((${floatRegexStrUnlimited}),(${floatRegexStrUnlimited})\\)`;
const polygonRegexStrUnlimited = `\\[((${geoPointStrUnlimited}),)+(${geoPointStrUnlimited})\\]`;
const polygonRegexStr = `^${polygonRegexStrUnlimited}$`;

export function isNonNegativeInt(str: string) {
  return str.match(nonNegativeIntRegex) !== null;
}

export function isFloat(str: string) {
  return str.match(floatRegexStr) !== null;
}

export function isPolygon(str: string) {
  const strWithoutWhitespace = str.replaceAll(/\s/g, '');
  return strWithoutWhitespace.match(polygonRegexStr) !== null;
}

export function parsePolygonStr(str: string): Polygon {
  if(!isPolygon(str))
    throw new Error('str is not a polygon string');

  const floatStringsItr = str.matchAll(new RegExp(floatRegexStrUnlimited, 'g'));
  const floatStrings = [...floatStringsItr];
  if(floatStrings.length % 2 !== 0)
    throw new Error('unexpected length of float strings arr');

  const polygon: Polygon = [];
  for(let i = 0; i < floatStrings.length; i += 2) {
    const lat = parseFloat(floatStrings[i][0]);
    const lng = parseFloat(floatStrings[i + 1][0]);
    polygon.push({lat, lng});
  }

  return polygon;
}

import {
  atom,
} from 'recoil';

import {
  AccountRes
} from '../shared/types';

const LS_ACCOUNT_DATA = 'accountData';

type SetSelf<T> = (arg0: T) => void;
type OnSet<T> = (arg0: (a0: T) => void) => void;

const syncAccount = () => ({trigger, setSelf, onSet}:
  {trigger: string, setSelf: SetSelf<AccountRes | null>, onSet: OnSet<AccountRes | null>}) => {

  if(trigger === 'get') {
    const accountDataAsStr: string | null = localStorage.getItem(LS_ACCOUNT_DATA);
    if(accountDataAsStr) {
      const accountData = JSON.parse(accountDataAsStr) as AccountRes;
      setSelf(accountData);
    }
  }

  onSet((accountData: AccountRes | null) => {
    localStorage.setItem(LS_ACCOUNT_DATA, JSON.stringify(accountData));
  });
};

export const accountAtom = atom<AccountRes | null>({
  key: 'account',
  default: null,
  effects_UNSTABLE: [
    syncAccount()
  ]
});
